<template>
    <div class="card-body  p-0" :class="loading ? 'whirl' : ''">
        <div class="btn-toolbar justify-content-end pb-sm-1 pt-2">
            <b-input-group class="input-group-sm">
                <b-input type="text" class="mw-100 wd-lg rounded" placeholder="Search for username" @change="usernameUpdated" v-model="endpointParams.username"></b-input>

                <b-input-group-append>
                    <b-button variant="link" class="text-secondary" v-b-tooltip.hover title="Previous page" @click="prevPage" :disabled="state.meta.current_page === 1"><i
                            class="fad fa-arrow-left"></i></b-button>
                    <b-button variant="link" class="text-secondary" v-b-tooltip.hover title="Next page" :disabled="state.meta.current_page === state.meta.last_page" @click="nextPage"><i
                            class="fad fa-arrow-right"></i></b-button>
                </b-input-group-append>
            </b-input-group>
        </div>

        <hr class="mt-1 mb-1"/>

        <div v-show="state.data.length" class="table-responsive text-nowrap d-none d-md-block">
            <table class="table table-striped table-bordered">
                <thead>
                <tr>
                    <th>{{ $t('affiliate.component.downline_browser.username') }}</th>
                    <th>{{ $t('affiliate.component.downline_browser.team_size') }}</th>
                    <th>{{ $t('affiliate.component.downline_browser.team_plants_bought') }}</th>
                    <th>{{ $t('affiliate.component.downline_browser.plants_bought') }}</th>
                    <th>{{ $t('affiliate.component.downline_browser.earnings') }}</th>
                    <th>{{ $t('affiliate.component.downline_browser.residual_bonus') }}</th>
                    <th class="text-right" v-if="endpointParams.layer === 1"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in state.data" :key="item.name">
                    <td>
                        {{ item.name }} <span class="small col-sponsor" v-if="item.network.sponsor">by {{ item.network.sponsor }}</span>
                    </td>
                    <td>
                        <span v-if="item.stats.team_size">{{ item.stats.team_size }} <i v-if="item.stats.team_size > 0" class="fad fa-users"></i></span>
                        <span v-if="!item.stats.team_size">-</span>
                    </td>
                    <td>
                        <span v-if="item.stats.team_plants_bought">{{ item.stats.team_plants_bought }} <i class="fad fa-seedling"></i></span>
                        <span v-if="!item.stats.team_plants_bought">-</span>
                    </td>
                    <td>
                        <span v-if="item.plants_bought || item.plants_active">
                            <span v-if="parseInt(item.plants_active) !== parseInt(item.plants_bought)">{{item.plants_active}} /</span>
                            {{ item.plants_bought }} <i v-if="item.plants_bought > 0" class="fad fa-seedling"></i>
                        </span>
                        <span v-else>-</span>
                    </td>
                    <td>
                        <span v-if="item.earnings">{{ item.earnings | currency }} EUR</span><span v-if="!item.earnings">-</span>
                    </td>
                    <td>
                        <span v-if="item.residual_bonus > 0"><i class="fad fa-box-open"></i> {{ item.residual_bonus | cannabis }}g</span>
                        <span v-if="!item.residual_bonus">-</span>
                    </td>
                    <td v-if="endpointParams.layer === 1" class="text-right">
                        <a title="Open chat" @click.prevent="createConversation(item)" href="" class="mr-2"><i class="fad fa-comments-alt"></i></a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div v-for="item in state.data" :key="item.name" class="d-block d-md-none p-3">
            <div>
                <span class="mr-2">{{ $t('affiliate.component.downline_browser.username') }}:</span>{{ item.name }} <span class="small col-sponsor" v-if="item.network.sponsor">by {{ item.network.sponsor }}</span>
            </div>
            <div>
                <span class="mr-2">{{ $t('affiliate.component.downline_browser.team_size') }}:</span>
                <span v-if="item.stats.team_size">{{ item.stats.team_size }} <i v-if="item.stats.team_size > 0" class="fad fa-users"></i></span>
                <span v-if="!item.stats.team_size">-</span>
            </div>
            <div>
                <span class="mr-2">{{ $t('affiliate.component.downline_browser.team_plants_bought') }}:</span>
                <span v-if="item.stats.team_plants_bought">{{ item.stats.team_plants_bought }} <i v-if="item.stats.team_plants_bought > 0" class="fad fa-seedling"></i></span>
                <span v-if="!item.stats.team_plants_bought">-</span>
            </div>
            <div>
                <span class="mr-2">{{ $t('affiliate.component.downline_browser.plants_bought') }}:</span>
                <span v-if="item.plants_bought">{{ item.plants_bought }} <i v-if="item.plants_bought > 0" class="fad fa-seedling"></i></span><span v-if="!item.plants_bought">-</span>
            </div>
            <div>
                <span class="mr-2">{{ $t('affiliate.component.downline_browser.earnings') }}:</span>
                <span v-if="item.earnings">{{ item.earnings | currency }} EUR</span><span v-if="!item.earnings">-</span>
            </div>
            <div>
                <span class="mr-2">{{ $t('affiliate.component.downline_browser.residual_bonus') }}:</span>
                <span v-if="item.residual_bonus > 0"><i class="fad fa-box-open"></i> {{ item.residual_bonus | cannabis }}g</span>
                <span v-if="!item.residual_bonus">-</span>
            </div>
            <div v-if="endpointParams.layer === 1">
                <a v-b-tooltip.hover title="Open chat" @click.prevent="createConversation(item)" href="" class="mr-2"><i class="fad fa-comments-alt"></i></a>
                <a v-b-tooltip.hover title="Send email (Beta)" :href="'mailto:' + item.email + '?body=Hello%20' + item.name + '%2C%0A%0A'" class=""><i class="fad fa-envelope"></i></a>
            </div>
            <hr/>
        </div>
        <div v-show="!state.data.length && !loading">
            <b-alert variant="primary" class="m-0 mt-2" show>
                No Members here
            </b-alert>
        </div>
    </div>
</template>
<style lang="scss">
    .card-downline-browser {

        td .col-sponsor {
            transition: .3s ease;
            opacity: 0;
        }

        tr:hover {
            .col-sponsor {
                opacity: 1;
            }
        }
    }

    .table-header-title {
        position: relative;

        > span {
            display: block;
            position: absolute;
            transform: rotate(-15deg);
            width: 300px;
            bottom: 10px;
            left: -20px;
        }
    }

</style>
<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';

    export default {
        extends: TableDataBrowser,
        props: ['layer'],
        data() {
            return {
                title: 'Level ',
                icon: 'fad fa-plus-square',
                endpoint: 'user/team/members?order_by=team_size',
            }
        },
        mounted() {

        },
        methods: {
            createConversation(user) {
                this.$api.post('chat/conversations', {name: user.name}).then(response => {
                    if (this.$store.state.chat.lastConversationId !== response.data.conversation.id) {
                        this.$store.commit('setCurrentConversation', response.data.conversation.id);
                        this.$router.push({name: 'Chat:Conversation', params: {conversationId: response.data.conversation.id}});
                    }
                });
            },
            usernameUpdated() {
                if (this.endpointParams.username.length >= 3) {
                    this.refresh();
                } else {
                    this.endpointParams.username = '';
                    this.refresh();
                }

                this.state.meta.current_page = 1;
            },
            init: function () {
                if (!this.layer) {
                    this.endpointParams.layer = 1;
                } else {
                    this.endpointParams.layer = this.layer;
                }

                this.title += this.endpointParams.layer;

                this.refresh();
            }
        }
    }
</script>
