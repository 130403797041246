<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">

            <b-row class="align-items-center mb-3" v-if="$store.getters.hasFirstline">
                <b-col lg="4" md="6" cols="8" offset="2" offset-md="3" offset-lg="0">
                    <lottie class="animated-fast fadeInDown mb-3" :options="unilevelPlanAnimation"/>
                </b-col>
                <b-col lg="4" md="6" class="order-lg-first">
                    <div class="card card-default card-stat mb-3 animated-fast fadeInLeft">
                        <div class="card-header">{{$t('affiliate.view.team.stats.total_team')}}<i
                                class="cai-user card-header-icon"></i></div>
                        <div class="card-body">
                            <formatted-number :amount="cartel.stats.team_size" type="members" animated=""/>
                        </div>
                    </div>
                    <div class="card card-default card-stat mb-3 animated-fast fadeInLeft" v-if="layers.length > 7">
                        <div class="card-header">{{$t('affiliate.view.team.stats.members')}}
                            <small>({{$t('affiliate.view.team.info.within_provisioned_levels')}})</small>
                            <i class="cai-user card-header-icon"></i></div>
                        <div class="card-body">
                            <formatted-number :amount="team_members" type="members" animated=""/>
                        </div>
                    </div>

                    <div class="card card-default card-stat mb-3 animated-fast fadeInRight">
                        <div class="card-header">{{$t('affiliate.view.team.stats.residual')}}
                            <small>({{$t('affiliate.view.team.info.last_grow')}})</small>
                            <i class="cai-profit card-header-icon"></i></div>
                        <div class="card-body">
                            <formatted-number :amount="cartel.residual_bonus" type="cannabis" animated=""/>
                        </div>
                    </div>
                </b-col>
                <b-col lg="4" md="6">
                    <div class="card card-default card-stat mb-3 animated-fast fadeInRight">
                        <div class="card-header">{{$t('affiliate.view.team.stats.team_profit')}}<i
                                class="cai-profit card-header-icon"></i></div>
                        <div class="card-body">
                            <formatted-number :amount="cartel.total_profit" type="fiat" animated=""/>
                        </div>
                    </div>
                    <div class="card card-default card-stat mb-3 animated-fast fadeInRight">
                        <div class="card-header">{{$t('affiliate.view.team.stats.plants')}}
                            <small v-if="layers.length > 7">({{$t('affiliate.view.team.info.within_provisioned_levels')}})</small>
                            <i class="cai-profit card-header-icon"></i></div>
                        <div class="card-body">
                            <formatted-number class="hide-on-hover animated-fast fadeInLeft" :amount="active_plants" type="plants" animated=""/>
                            <div class="show-on-hover animated-fast fadeInLeft" >
                                <formatted-number :amount="commissionable_plants" type="plants" animated=""/> <small>(sales)</small>
                            </div>
                        </div>
                    </div>
                    <div class="card card-default card-stat mb-3 animated-fast fadeInRight">
                        <div class="card-header">{{$t('affiliate.view.team.stats.residual')}}
                            <small>({{$t('affiliate.view.team.info.total')}})</small>
                            <i class="cai-profit card-header-icon"></i></div>
                        <div class="card-body">
                            <formatted-number :amount="cartel.residual_bonus" type="cannabis" animated=""/>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="row animated-fast fadeInUp">
                <div class="col-12">
                    <div class="card card-default mb-3">
                        <div class="card-header">{{$t('affiliate.view.team.invite.heading')}} <i
                                class="fad fa-envelope-open-text card-header-icon"></i></div>
                        <div class="card-body">
                            <b-row>
                                <b-col sm="6">
                                    <p class="card-text">{{$t('affiliate.view.team.invite.text1')}}.</p>

                                    <p class="card-text">{{$t('affiliate.view.team.invite.text2')}}</p>


                                    <b-btn class="btn-primary" @click="editWelcomeMessage()">{{ $t('affiliate.view.team.invite.button.edit_welcome_message') }}</b-btn>

                                </b-col>
                                <b-col sm="6" v-if="cartel.sponsor">

                                    <small>{{ $t('affiliate.view.team.invite.links.register.title') }}</small>
                                    <div class="float-right">
                                        <small>{{ $t('affiliate.view.team.invite.clicks', {clicks: ref_links.register.clicks}) }} | </small>
                                        <span class="mr-2 small">{{ $t('affiliate.view.team.invite.short_url') }}</span>
                                        <label class="switch switch-sm d-inline">
                                            <input type="checkbox" v-model="short_links" checked="checked"/>
                                            <span></span>
                                        </label>
                                    </div>

                                    <b-input-group @click="copyRegister()" v-b-tooltip.hover
                                                   :title="$t('affiliate.view.team.invite.links.register.tooltip')">
                                        <b-input onClick="this.select();" ref="refLinkRegister"
                                                 v-bind:value="(short_links ? ref_links.prefix_short : ref_links.prefix) + ref_links.register.code"
                                                 readonly></b-input>
                                        <b-input-group-append>
                                            <b-button variant="default"><i class="fad fa-clipboard"></i></b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <hr/>

                                    <div class="border-primary b p-3" v-if="cartel.sponsor">

                                        {{ $t('affiliate.view.team.invite.need_help') }} <br/>
                                        <i18n path="affiliate.view.team.invite.sponsor">
                                            <b slot="sponsor">{{cartel.sponsor.name}}</b>
                                        </i18n>

                                        <hr class="my-1"/>

                                        <a v-show="cartel.sponsor.social_media.linkedin !== ''" :href="'https://linkedin.com/in/' + cartel.sponsor.social_media.linkedin" target="_blank" class="social-media-contact-button mr-2 text-secondary"><i class="cai-linkedin"></i></a>
                                        <a v-show="cartel.sponsor.social_media.facebook !== ''" :href="'https://facebook.com/' + cartel.sponsor.social_media.facebook" target="_blank" class="social-media-contact-button mr-2 text-secondary"><i class="cai-facebook"></i></a>
                                        <a v-show="cartel.sponsor.social_media.twitter !== ''" :href="'https://twitter.com/' + cartel.sponsor.social_media.twitter" target="_blank" class="social-media-contact-button mr-2 text-secondary"><i class="cai-twitter"></i></a>
                                        <a v-show="cartel.sponsor.social_media.instagram !== ''" :href="'https://instagram.com/' + cartel.sponsor.social_media.instagram" target="_blank" class="social-media-contact-button mr-2 text-secondary"><i class="cai-instagram"></i></a>
                                        <a v-show="cartel.sponsor.social_media.telegram !== ''" :href="'https://t.me/' + cartel.sponsor.social_media.telegram" target="_blank" class="social-media-contact-button mr-2 text-secondary"><i class="cai-telegram"></i></a>
                                        <a v-show="cartel.sponsor.social_media.whatsapp !== ''" :href="'https://wa.me/' + cartel.sponsor.social_media.whatsapp" target="_blank" class="social-media-contact-button mr-2 text-secondary"><i class="cai-whatsapp"></i></a>

                                        <b-btn style="margin-top: -12px;" :disabled="loading" @click="createConversation(cartel.sponsor)">
                                            <i class="fad fa-envelope mr-2"></i>Chat
                                        </b-btn>

                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="$store.getters.hasFirstline">
                <div class="col-12 mb-5">

                    <template v-for="(layer, index) in layers">
                        <template v-if="index+1 < layers.length">
                            <div class="card card-default card-downline-browser  card-data-browser animated-fast fadeInUp mb-3"
                                 :style="{cursor: layer.open ? '' : 'pointer'}" :key="index">

                                <div class="card-header" @click="toggle(layer)">
                                    <i class="fad fa-plus-square" v-show="!layer.open"></i> <span class="ml-2">{{$t('affiliate.view.team.downline.layer')}} {{layer.label}}</span>
                                    <span class="ml-2 small">| {{layer.member_amount}} {{$t('affiliate.view.team.downline.members')}} | <span v-if="layer.plants_bought !== layer.plants_active">{{layer.plants_active}}/</span>{{layer.plants_bought}} {{$t('affiliate.view.team.downline.plants')}} | {{(layer.commission*100).toFixed(0)}}% | {{ layer.team_profit | currency}} EUR {{$t('affiliate.view.team.downline.earnings')}}</span>
                                </div>
                                <downline-browser :layer="layer.label" v-if="layer.open"/>
                            </div>
                        </template>

                        <template v-if="layers.length === index+1">
                            <div class="card card-gray animated-fast fadeInUp card-data-table mb-3" :key="index">
                                <div class="card-header">
                                    <span class="ml-2">{{$t('affiliate.view.team.downline.layer')}} {{layer.label}}</span>
                                    <span class="ml-2 small">| {{layer.member_amount}} {{$t('affiliate.view.team.downline.members')}} | <span v-if="layer.plants_bought !== layer.plants_active">{{layer.plants_active}}/</span>{{layer.plants_bought}} {{$t('affiliate.view.team.downline.plants')}}</span>
                                </div>
                            </div>
                        </template>
                    </template>

                </div>
            </div>
        </div>
    </ContentWrapper>
</template>
<style>
    .social-media-contact-button {
        font-size: 35px;
    }
</style>
<script>
    import Lottie from 'vue-lottie';
    import * as unilevelPlanAnimationData from '@/assets/animations/unilevel-plan.json';

    import DownlineBrowser from '../components/DownlineBrowser.vue';

    export default {
        components: {
            Lottie,
            DownlineBrowser
        },
        data() {
            return {
                unilevelPlanAnimation: {
                    animationData: unilevelPlanAnimationData.default,
                    loop: false
                },
                cartel: {
                    stats: {
                        team_total: 0,
                        team_first_line: 0,
                        team_last_day: 0
                    },
                    total_profit: 0,
                    residual_bonus: 0,
                    sponsor: null
                },
                commissionable_plants: 0,
                active_plants: 0,
                team_members: 0,
                layers: [],
                short_links: false,
                ref_links: {
                    website: {
                        code: '',
                        clicks: 0
                    },
                    register: {
                        code: '',
                        clicks: 0
                    },
                    prefix: '',
                    prefix_short: ''
                },
                filter: this.$options.filters,
                numberTransitionDuration: 200,
                loading: false
            }
        },
        methods: {
            editWelcomeMessage() {
                this.$swal.fire({
                    title: this.$t('affiliate.view.team.popup.edit_welcome_message.title'),
                    text: this.$t('affiliate.view.team.popup.edit_welcome_message.description'),
                    cancelButtonText: this.$t('affiliate.view.team.popup.edit_welcome_message.cancel_button'),
                    inputPlaceholder: this.$t('affiliate.view.team.popup.edit_welcome_message.placeholder'),

                    input: 'textarea',
                    showCancelButton: true,
                    confirmButtonText: this.$t('common.button.save.label'),
                    reverseButtons: true,
                    inputValue: this.$api.get('user/settings').then(response => {
                        return response.data.members_welcome_message || ''
                    })
                }).then(response => {
                    if (response.dismiss === 'cancel') {
                        this.$api.update('user/settings', {
                            members_welcome_message: null
                        })
                    } else if (response.value) {
                        this.$api.update('user/settings', {
                            members_welcome_message: response.value
                        })
                    }
                })
            },
            createConversation(user) {
                this.loading = true;
                this.$api.post('chat/conversations', {name: user.name}).then(response => {
                    this.$store.commit('setCurrentConversation', response.data.conversation.id);
                    this.$router.push({name: 'Chat:Conversation', params: {conversationId: response.data.conversation.id}});
                });
            },
            copyRegister() {
                this.$copyText(this.$refs.refLinkRegister.value);
                this.copy();
            },
            copy() {
                this.$notify({
                    group: 'main',
                    title: 'Ref-Link copied!',
                    type: 'success',
                    data: {
                        class: 'far fa-clipboard',
                    }
                });
            },
            toggle(layer) {
                this.layers.forEach(l => {
                    this.$set(l, 'open', false);
                });

                layer.open = true;
            }
        },
        beforeCreate() {
            this.$api.get('user/team').then((response) => {
                this.cartel = response.data;

                this.ref_links.register = response.data.referral_link_register;

                this.ref_links.prefix = process.env.VUE_APP_REFERRAL_URL;
                this.ref_links.prefix_short = process.env.VUE_APP_REFERRAL_SHORT_URL;
            });
            this.$api.get('user/team/layers').then((response) => {
                this.layers = response.data;

                this.layers.forEach((layer, key) => {
                    if (this.layers.length - 1 !== key) {
                        this.commissionable_plants += layer.plants_bought;
                        this.active_plants += layer.plants_active;
                        this.team_members += layer.member_amount;
                    }
                })
            });
        }
    }
</script>